/* General styles */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #1a1a2e;
    color: #e0e0e0;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Modern Header Styles */
.header-container {
    background: linear-gradient(to right, #2c202d, #34495e);
    padding: 1.5rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-link {
    text-decoration: none;
    display: inline-block; /* This will make the link only as wide as its content */
    width: auto; /* Ensures the link is only as wide as the content */
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
}

.site-title {
    color: #fff;
    font-size: 2.5rem;
    margin: 0;
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
    transition: color 0.3s ease;
}

.site-subtitle {
    color: #ff6f61;
    font-size: 1rem;
    margin: 0.5rem 0 0;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.header-link:hover .site-title {
    color: #ff6f61;
}

/* Main Layout Styles */
.main-content {
    display: flex;
    flex: 1;
}

/* Modern Side Menu Styles */
.side-menu {
    width: 220px;
    background-color: #1a1a2e;
    padding: 2rem 1rem;
    height: calc(100vh - 100px);
    position: sticky;
    top: 0;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ff6f61 #1a1a2e;
}

.side-menu nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-menu nav ul li {
    margin: 0.5rem 0;
}

.side-menu nav ul li a {
    display: block;
    padding: 0.8rem 0.8rem;
    color: #f0f3f4;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 6px;
    transition: all 0.3s ease;
    word-wrap: break-word;  /* Ensures long words wrap */
    hyphens: auto;         /* Adds hyphens when breaking words */
}

.side-menu nav ul li a:hover {
    background-color: rgba(255, 111, 97, 0.1);
    color: #ff6f61;
    transform: translateX(5px);
}

.side-menu nav ul li a.active {
    background-color: #ff6f61;
    color: white;
}

/* Content Area Styles */
.content {
    flex: 1;
    padding: 2rem;
    background-color: #1a1a2e;
    min-height: calc(100vh - 100px);
}

/* Main Content Styles */
main {
    max-width: 900px;
    margin: 0 auto;
    background-color: #2c3e50;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

section {
    margin-bottom: 2.5rem;
}

section h1 {
    color: #ff6f61;
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
    font-family: 'Playfair Display', serif;
    border-bottom: 3px solid #ff6f61;
    padding-bottom: 0.5rem;
}

section h2 {
    color: #ff6f61;
    font-size: 1.9rem;
    margin: 2rem 0 1rem;
    font-family: 'Playfair Display', serif;
}

section h3 {
    color: #ff6f61;
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
    font-family: 'Playfair Display', serif;
}

section h4 {
    color: #ff6f61;
    font-size: 1.2rem;
    font-family: 'Playfair Display', serif;
}

section p {
    color: #e0e0e0;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 1.0rem;
}

/* List Styles */
.enlarged-text {
    font-size: 1.1rem;
    line-height: 1.8;
}

ul, ol {
    margin-bottom: 1.5rem;
}

ul li, ol li {
    margin-bottom: 0.5rem;
    line-height: 1.8;
}

/* Image Styles */
img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1.5rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.calculator-container {
    background: transparent !important;
    padding: 0 !important;
    max-width: 100% !important;
    margin: 0 !important;
}

main.home-main {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}



/* Mobile Responsive Styles */
@media only screen and (max-width: 768px) {
    /* Header Mobile Styles */
    .header-container {
        padding: 1rem;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    
    .site-title {
        font-size: 1.8rem;
    }
    
    .site-subtitle {
        font-size: 0.8rem;
    }

    /* Layout Mobile Styles */
    .main-content {
        flex-direction: column;
    }

    /* Side Menu Mobile Styles */
    .side-menu {
        width: 100%;
        padding: 0.5rem;
        box-sizing: border-box;
        height: auto;
        position: static;
    }
    
    .side-menu nav ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        width: 100%;
        box-sizing: border-box;
    }
    
    .side-menu nav ul li {
        margin: 0;
        flex: 0 1 auto;
        min-width: fit-content;
    }
    
    .side-menu nav ul li a {
        text-align: center;
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    
    .side-menu nav ul li a:hover {
        transform: none;
    }

    /* Content Mobile Styles */
    .content {
        padding: 1rem;
        width: 100%;
        box-sizing: border-box;
    }
    
    main {
        width: 100%;
        box-sizing: border-box;
    }

    /* Typography Mobile Styles */
    section h1 {
        font-size: 1.8rem;
    }

    section h2 {
        font-size: 1.5rem;
    }

    section h3 {
        font-size: 1.2rem;
    }

    section p {
        font-size: 1rem;
    }

    .enlarged-text {
        font-size: 1rem;
    }
}
