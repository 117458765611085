.birth-chart-results {
    max-width: 1600px;
    margin: 10px auto;
    padding: 15px;
    background: #2d2d2d;
    border-radius: 20px;
    color: white;
    /* Removed fixed height to allow natural flow */
    display: flex;
    flex-direction: column;
    /* Changed from visible to auto to prevent overflow */
    overflow: auto;
}

.main-container {
    width: 100%;
    min-width: 1200px; /* This prevents content from getting squished */
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* Row layouts */
.row {
    display: flex;
    gap: 15px;
    width: 100%;
    min-width: 1200px; /* Match main-container min-width */
}

.first-row, .second-row {
    /* Changed from fixed height to min-height */
    min-height: 400px;
}

/* Birth Info Column */
.birth-info-column {
    width: 22%;
    display: flex;
    flex-direction: column;
}

/* Birth Info Styles */
.birth-info {
    background: #3d3d3d;
    border-radius: 10px;
    padding: 15px;
    border: 3px solid #ffd700;
    font-size: 0.9rem;
    flex-grow: 1;
    height: 100%;
}

.birth-info div {
    margin-bottom: 12px;
    line-height: 1.5;
}


/* Chart Styles */
.chart {
    background: #3d3d3d;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove all padding */
    overflow: hidden;
    position: relative;
}

.d1-chart {
    border: 3px solid #347dff;
    width: 36%;
}

.d9-chart {
    border: 3px solid #43cb91;
    width: 32%;
    flex-direction: column;
}

.d10-chart {
    border: 3px solid #ff8c00;
    width: 32%;
    flex-direction: column;
}

.d9-chart h3 {
    font-size: 1.3rem;
    font-weight: 600; /* 400 is normal and 700 is bold */
    margin: 10px 0px 5px 0px; /* Top Right Bottom Left */
    text-align: center;
}

.d10-chart h3 {
    font-size: 1.3rem;
    font-weight: 600; /* 400 is normal and 700 is bold */
    margin: 10px 0px 5px 0px; /* Top Right Bottom Left */
    text-align: center;
}

.d9-chart > div {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.d10-chart > div {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Chart SVG container */
.chart > div {
    width: 100%;
    height: 100%;
    position: relative;  /* For absolute positioning of SVG */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* SVG Sizing */
svg {
    width: 100%;
    height: 100%;
    max-height: none; /* Remove max-height restriction */
    max-width: none; /* Remove max-width restriction */
    transform: scale(1.1); /* Scale the chart */
}

/* Ensure charts maintain minimum dimensions */
.d1-chart, .d9-chart, .d10-chart {
    min-height: 400px;
    min-width: 400px; /* Add minimum width to prevent squishing */
    padding: 0; /* Remove all padding */
    overflow: hidden;
    display: flex;  /* Add this to help with centering */
    align-items: center;  /* Add this for vertical centering */
    justify-content: center;  /* Add this for horizontal centering */
}


/* Table Containers */
.d1-table-container {
    background: #3d3d3d;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow: visible; /* Changed from hidden to visible */
}

.d1-table-container {
    width: 38%;
    border: 3px solid #347dff;
}

.d1-table-container h3 {
    font-size: 1.5rem; /* Increased from 1.2rem */
    font-weight: bold; /* 700 is the same as bold */
    margin: 0 0 15px 0; /* top right bottom left */
    text-align: center;
}

.table-scroll-container {
    flex-grow: 1;
    overflow: visible; /* Changed from hidden to visible */
    /* Removed max-height restriction */
}

/* Table Styles */
.results-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 0.9rem;
}

.results-table th {
    text-align: left;
    padding: 3px 4px;
    border-bottom: 1px solid #2d2d2d;
    font-size: 1.2rem; /* Increased from 0.9rem */
    font-weight: 600;
    color: #ffffff;
    position: sticky;
    top: 0;
    background: #3d3d3d;
    z-index: 10;
}

.results-table td {
    padding: 3px 4px;
    border-bottom: 1px solid #2d2d2d;
    font-size: 0.85rem;
}

/* Column Widths */
.planet-column {
    width: 70px;
}

.rasi-column {
    width: 80px;
}

.nakshatra-column {
    width: 90px;
}

.pada-column {
    width: 40px;
}

/* Rasi Details */
.rasi-sign {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 0.85rem;
}

.rasi-degrees {
    font-size: 0.8rem;
    color: #cccccc;
}


/* Dasha Container */
.dasha-wrapper {
    width: 24%;
    /* Removed height restriction */
}

.dasha-container {
    background: #3d3d3d;
    border-radius: 10px;
    padding: 15px;
    /* Extend to full height */
    height: 100%;
    border: 3px solid #d580ff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.dasha-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Change from center to flex-start */
    margin-bottom: 8px;
}

.dasha-header h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
}

.dasha-subtitle {
    font-size: 0.9rem;
    color: #d1d1d1;
    margin-bottom: 8px;
}

.dasha-back-button {
    color: #ffffff;
    background: #fe2c55;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px 4px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 25px;
    margin-top: 20px; /* Increased to move the button further down */
    margin-left: auto; /* Push to the right side */
    margin-right: 0; /* Ensure it stays at the right edge */
    
    /* Add these lines for custom focus outline */
    outline: none; /* Remove default outline */
    position: relative; /* For pseudo-element positioning */
}

/* Add a custom focus style using a pseudo-element */
.dasha-back-button:focus {
    outline: none; /* Ensure no default outline */
}

.dasha-back-button:focus::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid white;
    border-radius: 6px 6px 12px 12px; /* Slightly larger to appear outside */
    pointer-events: none; /* Allow clicks to pass through */
}

.dasha-list-container {
    flex-grow: 1;
    overflow: visible; /* Changed from hidden to visible */
}

.dasha-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.dasha-item {
    display: grid;
    grid-template-columns: 20px 20px 1fr;
    gap: 25px;
    font-size: 0.8rem;
    color: #d1d1d1;
    cursor: pointer;
    padding: 3px 0px;
    align-items: center;
}

.antardasha-item {
    cursor: default;  /* This overrides any pointer cursor for antardasha items */
}

.dasha-item.active {
    font-weight: bold;
    color: #ffffff;
}

.dasha-planet {
    text-transform: capitalize;
}

.dasha-years {
    text-align: right;
}

.dasha-date-range {
    color: #fdabda;
    text-align: right;
    font-size: 0.8rem;
}


/* Karakas Container */
.karakas-wrapper {
    width: 12%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.karaka-table-wrapper {
    background: #3d3d3d;
    border-radius: 10px;
    padding: 15px;
    height: 85%;
    border: 3px solid #e8b171;
    overflow: visible;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.karaka-table-wrapper h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin: 0 0 8px 0;
}

.karaka-table-container {
    overflow: visible;
    flex-grow: 1;
}

.karaka-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.karaka-table th, .karaka-table td {
    padding: 4px 3px;
    font-size: 0.9rem;
}

.karaka-table th {
    text-align: left;
    border-bottom: 1px solid #2d2d2d;
    font-weight: 600;
    position: sticky;
    top: 0;
    background: #3d3d3d;
    z-index: 10;
}

.karaka-table td {
    border-bottom: 1px solid #2d2d2d;
}

/* Back Button */
.back-button {
    width: 100%;
    padding: 10px;
    background: #fe2c55;
    border: none;
    border-radius: 5px 5px 12px 12px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: block;
    margin-top: auto; /* Push to bottom of flex container */

    /* Add these lines for custom focus outline */
    outline: none; /* Remove default outline */
    position: relative; /* For pseudo-element positioning */
}

/* Add a custom focus style using a pseudo-element */
.back-button:focus {
    outline: none; /* Ensure no default outline */
}

.back-button:focus::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid white;
    border-radius: 7px 7px 14px 14px; /* Slightly larger to appear outside */
    pointer-events: none; /* Allow clicks to pass through */
}



/* Update media queries for better mobile support */
@media (max-width: 1200px) {
    /* Instead of rearranging content on smaller screens, 
       allow horizontal scrolling to see the full content */
    .birth-chart-results {
        overflow-x: auto; /* Enable horizontal scrolling */
    }
    
    /* No need to change layout for small screens,
       just maintain minimum sizes and allow scrolling */
    .row {
        flex-direction: row; /* Keep row layout */
        height: auto;
    }
    
    .first-row, .second-row {
        height: auto;
    }
    
    /* Maintain original widths instead of 100% */
    .birth-info-column {
        min-width: 250px;
    }
    
    .d1-table-container {
        min-width: 420px;
    }
    
    .dasha-wrapper {
        min-width: 250px;
    }
    
    .karakas-wrapper {
        min-width: 180px;
    }
}

