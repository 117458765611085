/* Add dark background to the entire page */
body {
    background-color: #1a1a1a;
}

.birth-chart-container {
    padding: 0 50px; /* Reduced from 100px */
}

.birth-chart-form {
    max-width: 700px; /* Reduced from 800px */
    margin: 20px auto; /* Reduced from 40px */
    padding: 25px 40px; /* Reduced from 40px 60px */
    background: #2d2d2d;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Container for form content */
.form-content {
    max-width: 620px; /* Reduced from 680px */
    margin: 0 auto;
}

h1 {
    color: #ffffff;
    margin-bottom: 25px; /* Reduced from 40px */
    text-align: center;
    font-size: 2.4rem !important;
}

.form-group {
    margin-bottom: 16px; /* Reduced from 28px */
}

/* Base form row styling */
.form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; /* Reduced from 24px */
    align-items: end;
}

/* Birth Date specific styling to ensure proper spacing */
.form-group:nth-child(2) .form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; /* Reduced from 24px */
}

/* Single column form groups */
.form-group-single .form-row {
    grid-template-columns: 1fr;
}

/* Container for the Latitude and Longitude fields*/
.coordinates-row {
    grid-template-columns: repeat(2, 1fr);
}

/* Container for the Ayanamsa and Node Type fields*/
.options-row {
    grid-template-columns: repeat(2, 1fr);
}

label {
    display: block;
    margin-bottom: 4px; /* Reduced from 8px */
    font-weight: 500;
    color: #e1e1e1;
    font-size: 0.9rem; /* Reduced from 1rem */
}

/* Optional label styling */
.optional-label{
    color: #888888;
    font-weight: normal;
    font-size: 0.8rem; /* Reduced from 0.9rem */
    margin-left: 4px;
}

input, select {
    width: 100%;
    padding: 8px 12px; /* Reduced from 12px 16px */
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.9rem; /* Reduced from 1rem */
    transition: all 0.3s ease;
    background: #f8fafc;
    box-sizing: border-box;
    height: 36px; /* Added fixed height */
}

input:focus, select:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.city-search {
    position: relative;
    width: 100%;
}

.city-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #2d2d2d;
    border: 2px solid #3d3d3d;
    border-radius: 0 0 8px 8px;
    max-height: 400px; /* Increased from 200px to show approximately 10 options */
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Darkened the shadow */
}

.city-option {
    padding: 7px 15px; /* First value is top & bottom padding. Second value is left & right padding */
    cursor: pointer;
    transition: background 0.2s ease;
    font-size: 0.9rem;
    color: #e1e1e1; /* Changed from default (black) to light gray */
}

.city-option:hover {
    background: #3d3d3d;
    color: #ff6f61; /* Changed to the site's accent color on hover */
}

button {
    width: 33%;
    padding: 0px;
    background: #fe2c55;
    color: white;
    border: none;
    border-radius: 5px 5px 12px 12px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.3s ease;
    height: 40px;
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    /* Add these lines for custom focus outline */
    outline: none; /* Remove default outline */
    position: relative; /* For pseudo-element positioning */
}

/* Add a custom focus style using a pseudo-element */
button:focus {
    outline: none; /* Ensure no default outline */
}

button:focus::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid white;
    border-radius: 7px 7px 17px 17px; /* Slightly larger to appear outside */
    pointer-events: none; /* Allow clicks to pass through */
}

button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

select option[value=""][disabled] {
    color: #757575;
}

input::placeholder {
    color: #757575;
}

/* Custom scrollbar for city dropdown */
.city-dropdown::-webkit-scrollbar {
    width: 20px;
}

.city-dropdown::-webkit-scrollbar-track {
    background: #2d2d2d;
    border-radius: 0 0 8px 0;
}

.city-dropdown::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 4px;
}

.city-dropdown::-webkit-scrollbar-thumb:hover {
    background: #ff6f61; /* Changed to the site's accent color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .birth-chart-container {
        padding: 0 15px; /* Reduced padding on mobile */
    }
    
    .birth-chart-form {
        padding: 20px 15px; /* Reduced from 30px 20px */
        margin: 15px; /* Reduced from 20px */
    }

    .form-row {
        grid-template-columns: 1fr;
        gap: 12px; /* Reduced from 16px */
    }

    /* Ensure all options stack vertically on mobile */
    .options-row {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 1.6rem; /* Reduced from 1.8rem */
        margin-bottom: 20px;
    }
    
    .form-group {
        margin-bottom: 12px; /* Further reduced for mobile */
    }
    
    button {
        height: 35px; /* Slightly smaller on mobile */
    }
}

.error-message {
    color: #dc3545;
    font-size: 0.75rem; /* Reduced from 0.8rem */
    margin-top: 3px; /* Reduced from 4px */
}

input.error {
    border-color: #dc3545;
    background-color: #fff;
}

.city-search .error-message {
    color: #dc3545;
    font-size: 0.75rem; /* Reduced from 0.8rem */
    margin-top: 3px; /* Reduced from 4px */
    position: absolute;
}
